import React, { useEffect, useState } from 'react';
import { LanguageSelect, LogoutButton } from '@components';
import { history, routerPath } from '../../Config';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardingFlowHandlerActions } from '../../Redux/Services/OnboardingFlowHandler/actions';
import {
  languageSelector,
  OnboardingFlowState
} from '../../Redux/Selectors/questionnaireFlowHandle';
import { IconWrapper } from '../../Containers/ApplicationKYC/LegalEntityQuestionnaire/components/icon/icon.styles';
import {
  HeaderWrapper,
  LanguageSelectStyles
} from '../../Containers/LoginContainer/styles/login.styles';
import { AppConfigActions, AuthActions } from '../../Redux';
import { NOTIFICATION_TYPES, Notification, UserActive } from '../../Components';
import IdleTimer from 'react-idle-timer';
import { useInterval } from '../../Redux/useInterval';
import { Logos } from './Logo/logo';
import { EnvironmentBanner } from './EnvironmentBanner/EnvironmentBanner';
import { Button } from '@single-platform/button';
import { HiddenIframe } from '../../Containers/SSOLoginContainer/SSOLoginContainer.styles';
import { storageHandler } from '../../Containers/SSOLoginContainer/storageHandler';
import { RefreshToken } from '../../Utilities/refreshToken';
import { t } from 'i18next';
import { BackButtonStyle, Container } from './PartTop.style';
import { XLogo } from '../../Assets/images/logos';

export const redirectToSmeGO = (isError, applicationState) => {
  const currentUserInformation = storageHandler.getSessionStorage('token');

  if (currentUserInformation) {
    const iframe = document.getElementById('smego_iframe');
    if (isError === 'error')
      Notification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('no_redirect_url_sso_login')
      });

    iframe.contentWindow.postMessage(
      { message: 'redirect', data: currentUserInformation },
      process.env.REACT_APP_SMEGO_URL
    );

    const getUrl = () => {
      if (
        applicationState.state === 'WaitingForAmlOfficerKycReview' ||
        applicationState.state === 'OnboardingComplete'
      ) {
        const url = new URL(currentUserInformation.redirectUrl);

        url.searchParams.append('onboardingSuccess', true);

        return url.toString();
      } else {
        return currentUserInformation.redirectUrl;
      }
    };

    setTimeout(
      () => {
        window.open(getUrl(), '_self');
        sessionStorage.clear();
      },
      isError ? 1000 : 100
    );
  } else {
    Notification({
      type: NOTIFICATION_TYPES.ERROR,
      message: t('no_redirect_url_sso_login')
    });
    setTimeout(() => {
      sessionStorage.clear();
      window.location.href = process.env.REACT_APP_SMEGO_URL;
    }, 2000);
  }
};

export const PartTop = () => {
  const onboardingFlowState = useSelector(OnboardingFlowState);
  const applicationState = useSelector(
    (state) => state?.KYCReducers.applicationKYC?.applicationState
  );
  const language = useSelector(languageSelector);
  const [isUserloggedIn, setIsUserloggedIn] = useState(false);
  const [isInactiveModalOpen, setIsInactiveModalOpen] = useState(false);
  const [expiresIn, setExpiresIn] = useState(null);
  const [refreshToken, setRefreshToken] = useState('');
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem('language') || 'lt-LT'
  );
  const [smeGoSession, setSmeGoSession] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  const isLoggedIn = useSelector((state) => state.OnboardingFlowReducer.logged);
  const dispatch = useDispatch();
  const isMobileApp = storageHandler.getSessionStorage('mobileapp');
  const isExternalPersons = history.location.pathname.includes('/Company/');

  const handleHeight = () => {
    setIsAtTop(window.scrollY === 0);
  };

  useEffect(() => {
    handleHeight();
    document.addEventListener('scroll', handleHeight);
    return () => document.removeEventListener('scroll', handleHeight);
  }, [window.scrollY]);

  useInterval(
    async () => {
      await dispatch(RefreshToken());
    },
    isUserloggedIn ? 120000 : null
  );

  const onClickHandler = () => {
    if (!isUserloggedIn && !isExternalPersons) history.push(routerPath.index);
  };

  const handleOnIdle = () => {
    if (isInactiveModalOpen) return;
    setIsInactiveModalOpen(true);
  };

  useEffect(() => {
    if (onboardingFlowState.logged) {
      setRefreshToken(JSON.parse(sessionStorage.getItem('token')).refreshToken);
      dispatch(OnboardingFlowHandlerActions.isLoggedIn());
      setIsUserloggedIn(onboardingFlowState.logged);
      if (refreshToken) {
        setExpiresIn(240000);
      }
      if (applicationState && language !== currentLanguage) {
        setCurrentLanguage(language);
        dispatch(
          AppConfigActions.updateApplicationSettings({
            applicationId: applicationState.applicationId,
            language
          })
        );
      }
    } else {
      setIsUserloggedIn(false);
      if (language !== currentLanguage) setCurrentLanguage(language);
    }
  }, [onboardingFlowState.logged, applicationState, language]);

  useEffect(() => {
    if (smeGoSession) {
      dispatch(AuthActions.initiatedSSOLogin());
    } else {
      dispatch(AuthActions.resetSSOLogin());
    }
  }, [smeGoSession]);

  const isSmeGoSession = storageHandler.getSessionStorage('smeGoSession');

  useEffect(() => setSmeGoSession(isSmeGoSession), [isSmeGoSession]);

  return (
    <>
      {isUserloggedIn && expiresIn && (
        <IdleTimer
          timeout={expiresIn}
          onIdle={handleOnIdle}
          debounce={15000} // checks if user is in idle state every 15 seconds
        />
      )}
      {isUserloggedIn && isInactiveModalOpen && (
        <UserActive
          open={isInactiveModalOpen}
          onClose={() => {
            setIsInactiveModalOpen(false);
          }}
        />
      )}
      {
        <HeaderWrapper isAtTop={isAtTop} isLoggedIn={isLoggedIn}>
          {(!smeGoSession || isMobileApp) && (
            <IconWrapper onClick={onClickHandler}>
              <Logos isBank={process.env.REACT_APP_TRADEMARK.includes('Bank')} />
            </IconWrapper>
          )}

          <Container>
            {process.env.REACT_APP_ENV !== 'PROD' && (
              <EnvironmentBanner environmentName={process.env.REACT_APP_ENV} />
            )}
            {!smeGoSession && <LogoutButton logged={isUserloggedIn} />}
            {!isLoggedIn && (
              <LanguageSelectStyles>
                <LanguageSelect />
              </LanguageSelectStyles>
            )}
            {smeGoSession && (
              <BackButtonStyle>
                <Button onClick={() => redirectToSmeGO(null, applicationState)}>
                  <XLogo />
                </Button>
              </BackButtonStyle>
            )}
          </Container>
          {smeGoSession && !isMobileApp && (
            <HiddenIframe src={process.env.REACT_APP_SMEGO_URL} id="smego_iframe"></HiddenIframe>
          )}
        </HeaderWrapper>
      }
    </>
  );
};
