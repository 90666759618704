export const storageHandler = {
  getSessionStorage: (key) => {
    const item = sessionStorage.getItem(key);
    try {
      return JSON.parse(item);
    } catch {
      return item;
    }
  },
  setSessionStorage: (key, value) => {
    const storedValue = typeof value === 'object' ? JSON.stringify(value) : value;
    sessionStorage.setItem(key, storedValue);
  },
  getLocalStorage: (key) => {
    const item = localStorage.getItem(key);
    try {
      return JSON.parse(item);
    } catch {
      return item;
    }
  },
  setLocalStorage: (key, value) => {
    const storedValue = typeof value === 'object' ? JSON.stringify(value) : value;
    localStorage.setItem(key, storedValue);
  },
  switchStorage: (key, storageType) => {
    let sourceStorage, targetStorage;
    if (storageType === 'sessionStorage') {
      sourceStorage = localStorage;
      targetStorage = sessionStorage;
    } else {
      sourceStorage = sessionStorage;
      targetStorage = localStorage;
    }

    const item = sourceStorage.getItem(key);
    if (item !== null) {
      sourceStorage.removeItem(key);
      targetStorage.setItem(key, item);
    }
  }
};
